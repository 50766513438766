<template>
  <div>
    <div ref="dataTable"></div>
    <v-card flat class="rounded-xl pb-4">
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="this.$t('search.title')"
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="2">
            <v-select
            v-model="state"
            :items="states"
            :label="this.$t('status.title')"
            clearable
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-select
            v-model="type"
            :items="types"
            :label="this.$t('type.title')"
            clearable
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-select
            v-model="paymentMethod"
            :items="methods"
            :label="this.$t('payment.title')"
            clearable
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-select
            v-model="shoppingCenterId"
            :items="shops"
            item-text="name"
            item-value="id"
            :label="this.$t('shoppingCenters.shoppingCenter')"
            clearable
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-menu
              v-model="menuDateFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom"
                  :label="dateFromLabel"
                  persistent-hint
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                no-title
                @input="menuDateFrom = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-menu
              v-model="menuDateTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateTo"
                  :label="dateToLabel"
                  persistent-hint
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                no-title
                @input="menuDateTo = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        @update:page="$vuetify.goTo($refs.dataTable)"
        @update:sort-by="sortBy"
        @update:sort-desc="sortDesc"
        :headers="headers"
        :items="orders"
        :items-per-page="itemsPerPage"
        :loading="loading"
        :page="page"
        @click:row="details"
        @pagination="pagination"
        :server-items-length="totalItems"
        hide-default-footer
        :multi-sort="false"
        :must-sort="true"
        ><template v-slot:item.created="{ item }">
          {{ item.created | dateFormat('YYYY-MM-DD') }}
        </template>
        <template v-slot:item.state="{ item }">
          <div>{{ item.state }}</div>
          <div v-if="item.stateChangeDate" class="text-caption">{{ item.stateChangeDate | dateFormat('YYYY-MM-DD') }}</div>
        </template>
        <template v-slot:item.type="{ item }">
          <div>{{ item.type }}</div>
          <div v-if="item.isVisibleForCashier" class="text-caption">
            <v-chip class='px-1' x-small color='light-green'>
              {{ $t('orderStates.PassedToCashiers') }}
          </v-chip></div>
        </template>
        <template v-slot:item.giftCardsChargeAmount="{ item }">
          <div v-if="item.giftCardsChargeAmount">{{ item.giftCardsChargeAmount.toFixed(2) }} {{ getLocalCurrency }}</div>
        </template>
        <template v-slot:item.shippingPrice="{ item }">
          <div v-if="item.shippingPrice">{{ item.shippingPrice.toFixed(2) }} {{ getLocalCurrency }}</div>
        </template>
        <template v-slot:item.shoppingCenters="{ item }">
          <div v-if="item.shoppingCenters.length > 1">wiele galerii</div>
          <div v-else>{{ item.shoppingCenters[0].name }}</div>
        </template>
        <template v-slot:item.serviceFee="{ item }">
          <div v-if="item.serviceFee">{{ item.serviceFee.toFixed(2) }} {{ getLocalCurrency }}</div>
        </template>
        <template v-slot:item.totalChargeAmount="{ item }">
          <div v-if="item.totalChargeAmount">{{ item.totalChargeAmount.toFixed(2) }} {{ getLocalCurrency }}</div>
        </template>
        <template v-slot:footer v-if="pageCount > 1">
          <v-pagination v-model="page" @input="handlePageChange" class="mt-4" :length="pageCount" :total-visible="7" circle/>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
import _ from 'lodash'
export default {
  name: 'Orders',
  data () {
    return {
      loading: false,
      states: [
        { text: this.$t('orderStates.Unpaid'), value: 0 },
        { text: this.$t('orderStates.Paid'), value: 1 },
        { text: this.$t('orderStates.AssignedTokens'), value: 2 },
        { text: this.$t('orderStates.GeneratedActivationCodes'), value: 3 },
        { text: this.$t('orderStates.Sent'), value: 5 },
        { text: this.$t('orderStates.ReadyForPickup'), value: 7 },
        { text: this.$t('orderStates.Received'), value: 8 },
        { text: this.$t('orderStates.Completed'), value: 98 },
        { text: this.$t('orderStates.Cancelled'), value: 99 }
      ],
      types: [
        { text: this.$t('orderTypes.shipping'), value: 1 },
        { text: this.$t('orderTypes.pickup'), value: 2 },
        { text: this.$t('orderTypes.clickAndCollect'), value: 3 }
      ],
      methods: [
        { text: this.$t('paymentMethods.onlinePayment'), value: 1 },
        { text: this.$t('paymentMethods.traditionalTransfer'), value: 2 }
      ],
      shops: [],
      orders: [],
      search: '',
      dateFrom: null,
      dateTo: null,
      state: null,
      type: null,
      shoppingCenterId: null,
      menuDateFrom: false,
      menuDateTo: false,
      paymentMethod: null,
      page: 1,
      itemsPerPage: 25,
      orderBy: null,
      orderDesc: null,
      totalItems: null,
      dateFromLabel: this.$t('date.dateFrom'),
      dateToLabel: this.$t('date.dateTo'),
      headers: [
        { text: this.$t('orderHeaders.date'), align: 'start', value: 'created' },
        { text: this.$t('orderHeaders.orderNumber'), sortable: false, value: 'businessId' },
        { text: this.$t('orderHeaders.buyer'), sortable: false, value: 'receiverName' },
        { text: this.$t('orderHeaders.email'), sortable: false, value: 'email' },
        { text: this.$t('orderHeaders.status'), sortable: false, value: 'state' },
        { text: this.$t('orderHeaders.paymentMethod'), sortable: false, value: 'paymentMethod' },
        { text: this.$t('orderHeaders.type'), sortable: false, value: 'type' },
        { text: this.$t('orderHeaders.shoppingCenterName'), sortable: false, value: 'shoppingCenters' },
        { text: this.$t('orderHeaders.card'), value: 'giftCardsChargeAmount' },
        { text: this.$t('orderHeaders.fee'), value: 'serviceFee' },
        { text: this.$t('orderHeaders.shipping'), value: 'shippingPrice' },
        { text: this.$t('orderHeaders.totalAmount'), value: 'totalChargeAmount' }
      ]
    }
  },
  async created () {
    if (this.$route.query.page) this.page = +this.$route.query.page
    if (this.$route.query.state) this.state = +this.$route.query.state
    if (this.$route.query.type) this.type = +this.$route.query.type
    if (this.$route.query.paymentMethod) this.paymentMethod = +this.$route.query.paymentMethod
    if (this.$route.query.shoppingCenterId) this.shoppingCenterId = +this.$route.query.shoppingCenterId
    if (this.$route.query.dateFrom) this.dateFrom = this.$route.query.dateFrom
    if (this.$route.query.dateTo) this.dateTo = this.$route.query.dateTo

    await this.getOrders()
    this.checkAreOrdersVisibleForCashier(this.orders)
    await this.getCenters()
    this.debouncedGetOrders = _.debounce(this.getOrders, 500)
  },
  computed: {
    pageCount () {
      try {
        return Math.ceil(this.totalItems / this.itemsPerPage)
      } catch {
        return 1
      }
    },
    getLocalCurrency () {
      return localStorage.getItem('currency')
    }
  },
  watch: {
    search () {
      this.page = 1
      this.debouncedGetOrders()
    },
    orders () {
      this.checkAreOrdersVisibleForCashier(this.orders)
    },
    state () {
      this.page = 1
      this.getOrders()
    },
    type () {
      this.page = 1
      this.getOrders()
    },
    paymentMethod () {
      this.page = 1
      this.getOrders()
    },
    shoppingCenterId () {
      this.page = 1
      this.getOrders()
    },
    dateFrom () {
      this.page = 1
      this.getOrders()
    },
    dateTo () {
      this.page = 1
      this.getOrders()
    },
    orderBy () {
      this.page = 1
      this.getOrders()
    },
    orderDesc () {
      this.page = 1
      this.getOrders()
    }
  },
  methods: {
    async resetParams () {
      this.search = ''
      this.dateFrom = null
      this.dateTo = null
      this.type = null
      this.shoppingCenterId = null
      this.paymentMethod = null
      this.page = 1

      await this.getOrders()
    },
    async getOrders () {
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')
      let url = `?page=${this.page}&size=${this.itemsPerPage}&countryCode=${countryCode}`

      if (this.state) {
        url += `&state=${this.state}`
      }
      if (this.type) {
        url += `&type=${this.type}`
      }
      if (this.paymentMethod) {
        url += `&paymentMethod=${this.paymentMethod}`
      }
      if (this.shoppingCenterId) {
        url += `&shoppingCenterId=${this.shoppingCenterId}`
      }
      if (this.dateFrom) {
        url += `&dateFrom=${this.dateFrom}`
      }
      if (this.dateTo) {
        url += `&dateTo=${this.dateTo}`
      }
      if (this.orderBy) {
        url += `&orderBy=${this.orderBy}`
        if (this.orderDesc) {
          url += `&isAscending=${this.orderDesc}`
        }
      }
      if (this.search) {
        url += `&search=${this.search}`
      }

      window.history.pushState(null, '', url)

      try {
        const { data } = await Service.get(`Orders/InProgress${url}`)
        this.orders = data.results
        this.totalItems = data.count
      } catch (error) {
        if (this.$store.getters.userRoleId === '3') {
          await this.$router.push({ path: '/cashiers-wrap' })
        }
      }
      this.loading = false
    },
    checkAreOrdersVisibleForCashier (orders) {
      orders.forEach((order) => {
        if ((order.type === 'Odbiór w centrum' || order.type === 'Self-pickup at shopping center') && ((order.state === 'Opłacone' || order.state === 'Paid') || (order.state === 'Gotowe do odbioru' || order.state === 'Ready for pickup') || (order.state === 'Odebrane' || order.state === 'Received'))) {
          order.isVisibleForCashier = true
        }
      })
    },
    sortBy (options) {
      this.orderBy = options[0]
    },
    sortDesc (options) {
      this.orderDesc = options[0]
      console.log(options[0])
    },
    async getCenters () {
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')
      try {
        const { data } = await Service.get(`/ShoppingCenters/Management?countryCode=${countryCode}`)
        this.shops = data
      } catch (error) {}
      this.loading = false
    },
    handlePageChange (value) {
      this.page = value
      this.getOrders()
    },
    pagination (options) {
      this.page = options.page
      this.itemsPerPage = options.itemsPerPage
    },
    details (item) {
      this.$router.push({ name: 'Order', params: { id: item.id } })
    }
  }
}
</script>
