var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"dataTable"}),_c('v-card',{staticClass:"rounded-xl pb-4",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":this.$t('search.title'),"single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-select',{attrs:{"items":_vm.states,"label":this.$t('status.title'),"clearable":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-select',{attrs:{"items":_vm.types,"label":this.$t('type.title'),"clearable":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-select',{attrs:{"items":_vm.methods,"label":this.$t('payment.title'),"clearable":""},model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-select',{attrs:{"items":_vm.shops,"item-text":"name","item-value":"id","label":this.$t('shoppingCenters.shoppingCenter'),"clearable":""},model:{value:(_vm.shoppingCenterId),callback:function ($$v) {_vm.shoppingCenterId=$$v},expression:"shoppingCenterId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.dateFromLabel,"persistent-hint":"","readonly":"","clearable":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateFrom),callback:function ($$v) {_vm.menuDateFrom=$$v},expression:"menuDateFrom"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menuDateFrom = false}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.dateToLabel,"persistent-hint":"","clearable":"","readonly":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateTo),callback:function ($$v) {_vm.menuDateTo=$$v},expression:"menuDateTo"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menuDateTo = false}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"page":_vm.page,"server-items-length":_vm.totalItems,"hide-default-footer":"","multi-sort":false,"must-sort":true},on:{"update:page":function($event){return _vm.$vuetify.goTo(_vm.$refs.dataTable)},"update:sort-by":_vm.sortBy,"update:sort-desc":_vm.sortDesc,"click:row":_vm.details,"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created,'YYYY-MM-DD'))+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.state))]),(item.stateChangeDate)?_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm._f("dateFormat")(item.stateChangeDate,'YYYY-MM-DD')))]):_vm._e()]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.type))]),(item.isVisibleForCashier)?_c('div',{staticClass:"text-caption"},[_c('v-chip',{staticClass:"px-1",attrs:{"x-small":"","color":"light-green"}},[_vm._v(" "+_vm._s(_vm.$t('orderStates.PassedToCashiers'))+" ")])],1):_vm._e()]}},{key:"item.giftCardsChargeAmount",fn:function(ref){
var item = ref.item;
return [(item.giftCardsChargeAmount)?_c('div',[_vm._v(_vm._s(item.giftCardsChargeAmount.toFixed(2))+" "+_vm._s(_vm.getLocalCurrency))]):_vm._e()]}},{key:"item.shippingPrice",fn:function(ref){
var item = ref.item;
return [(item.shippingPrice)?_c('div',[_vm._v(_vm._s(item.shippingPrice.toFixed(2))+" "+_vm._s(_vm.getLocalCurrency))]):_vm._e()]}},{key:"item.shoppingCenters",fn:function(ref){
var item = ref.item;
return [(item.shoppingCenters.length > 1)?_c('div',[_vm._v("wiele galerii")]):_c('div',[_vm._v(_vm._s(item.shoppingCenters[0].name))])]}},{key:"item.serviceFee",fn:function(ref){
var item = ref.item;
return [(item.serviceFee)?_c('div',[_vm._v(_vm._s(item.serviceFee.toFixed(2))+" "+_vm._s(_vm.getLocalCurrency))]):_vm._e()]}},{key:"item.totalChargeAmount",fn:function(ref){
var item = ref.item;
return [(item.totalChargeAmount)?_c('div',[_vm._v(_vm._s(item.totalChargeAmount.toFixed(2))+" "+_vm._s(_vm.getLocalCurrency))]):_vm._e()]}},(_vm.pageCount > 1)?{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.pageCount,"total-visible":7,"circle":""},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }