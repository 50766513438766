<template>
  <div>
    <div ref="dataTable"></div>
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('completedOrders.searchLabel')"
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="2">
            <v-select
              v-model="state"
              :items="states"
              :label="$t('completedOrders.status')"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-select
              v-model="paymentMethod"
              :items="methods"
              :label="$t('completedOrders.paymentMethodLabel')"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-menu
              v-model="menuDateFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom"
                  :label="$t('completedOrders.dateFromLabel')"
                  persistent-hint
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                no-title
                @input="menuDateFrom = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-menu
              v-model="menuDateTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateTo"
                  :label="$t('completedOrders.dateToLabel')"
                  persistent-hint
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                no-title
                @input="menuDateTo = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        @update:page="$vuetify.goTo($refs.dataTable)"
        @update:sort-by="sortBy"
        @update:sort-desc="sortDesc"
        :headers="headers"
        :items="orders"
        :items-per-page="itemsPerPage"
        :loading="loading"
        :page="page"
        @click:row="details"
        @pagination="pagination"
        :server-items-length="totalItems"
        hide-default-footer
        :multi-sort="false"
        :must-sort="true"
      ><template v-slot:item.ordinalNumber="{ item, index }">
        {{ index+1 }}.
      </template>
        <template v-slot:item.created="{ item }">
        {{ item.created | dateFormat('YYYY-MM-DD') }}
      </template>
        <template v-slot:item.state="{ item }">
          <div>{{ item.state }}</div>
          <div v-if="item.stateChangeDate" class="text-caption">{{ item.stateChangeDate | dateFormat('YYYY-MM-DD') }}</div>
        </template>
        <template v-slot:item.lastPickUpCodeSentDates="{ item }">
          <div class="text-caption">{{ item.lastPickUpCodeSentDate | dateFormat("DD.MM.YYYY HH:mm") }}</div>
          <div v-if='item.lastPickUpCodeSentDate' class="text-caption">
            <v-chip class='px-2' x-small color='primary'>
              {{ $t('cashierOrder.sent') }} {{ howManyTimes(item.sentPickUpCodeCount) }}
            </v-chip></div>
        </template>
        <template v-slot:item.totalChargeAmount="{ item }">
          <div v-if="item.totalChargeAmount">{{ item.totalChargeAmount.toFixed(2) }} {{ getLocalCurrency }}</div>
        </template>
        <template v-slot:footer v-if="pageCount > 1">
          <v-pagination v-model="page" @input="handlePageChange" class="mt-4" :length="pageCount" :total-visible="7" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
import _ from 'lodash'
import { parseHowManyTimes } from '@/utils/helpers'
export default {
  name: 'CashierOrders',
  data () {
    return {
      loading: false,
      states: [
        { text: this.$t('orderStates.Paid'), value: 1 },
        { text: this.$t('orderStates.ReadyForPickup'), value: 7 },
        { text: this.$t('orderStates.Received'), value: 8 }
      ],
      methods: [
        { text: this.$t('paymentMethods.onlinePayment'), value: 1 },
        { text: this.$t('paymentMethods.traditionalTransfer'), value: 2 }
      ],
      orders: [],
      search: '',
      dateFrom: null,
      dateTo: null,
      state: null,
      type: null,
      menuDateFrom: false,
      menuDateTo: false,
      paymentMethod: null,
      page: 1,
      itemsPerPage: 25,
      orderBy: null,
      orderDesc: null,
      totalItems: null,
      headers: [
        { text: 'Lp.', align: 'start', sortable: false, value: 'ordinalNumber' },
        { text: this.$t('orderHeaders.date'), align: 'start', sortable: false, value: 'created' },
        { text: this.$t('orderHeaders.orderNumber'), sortable: false, value: 'businessId' },
        { text: this.$t('orderHeaders.status'), sortable: false, value: 'state' },
        { text: this.$t('orderHeaders.paymentMethod'), sortable: false, value: 'paymentMethod' },
        { text: this.$t('orderHeaders.lastPickupCodeSent'), sortable: false, value: 'lastPickUpCodeSentDates' },
        { text: this.$t('orderHeaders.totalAmount'), sortable: false, value: 'totalChargeAmount' }
      ]
    }
  },
  async created () {
    if (this.$route.query.page) this.page = +this.$route.query.page
    if (this.$route.query.state) this.state = +this.$route.query.state
    if (this.$route.query.type) this.type = +this.$route.query.type
    if (this.$route.query.paymentMethod) this.paymentMethod = +this.$route.query.paymentMethod
    if (this.$route.query.dateFrom) this.dateFrom = this.$route.query.dateFrom
    if (this.$route.query.dateTo) this.dateTo = this.$route.query.dateTo

    await this.getOrders()
    this.debouncedGetOrders = _.debounce(this.getOrders, 500)
  },
  computed: {
    pageCount () {
      try {
        return Math.ceil(this.totalItems / this.itemsPerPage)
      } catch {
        return 1
      }
    },
    howManyTimes () {
      return (count) => parseHowManyTimes(count, this.$i18n)
    },
    getLocalCurrency () {
      return localStorage.getItem('currency')
    }
  },
  watch: {
    search () {
      this.page = 1
      this.debouncedGetOrders()
    },
    state () {
      this.page = 1
      this.getOrders()
    },
    type () {
      this.page = 1
      this.getOrders()
    },
    paymentMethod () {
      this.page = 1
      this.getOrders()
    },
    shoppingCenterId () {
      this.page = 1
      this.getOrders()
    },
    dateFrom () {
      this.page = 1
      this.getOrders()
    },
    dateTo () {
      this.page = 1
      this.getOrders()
    },
    orderBy () {
      this.page = 1
      this.getOrders()
    },
    orderDesc () {
      this.page = 1
      this.getOrders()
    }
  },
  methods: {
    async getOrders () {
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')
      let url = `?page=${this.page}&size=${this.itemsPerPage}&countryCode=${countryCode}`

      if (this.state) {
        url += `&state=${this.state}`
      }
      if (this.type) {
        url += `&type=${this.type}`
      }
      if (this.paymentMethod) {
        url += `&paymentMethod=${this.paymentMethod}`
      }
      if (this.dateFrom) {
        url += `&dateFrom=${this.dateFrom}`
      }
      if (this.dateTo) {
        url += `&dateTo=${this.dateTo}`
      }
      if (this.orderBy) {
        url += `&orderBy=${this.orderBy}`
        if (this.orderDesc) {
          url += `&isAscending=${this.orderDesc}`
        }
      }
      if (this.search) {
        url += `&search=${this.search}`
      }

      window.history.pushState(null, '', url)

      try {
        const { data } = await Service.get(`Cashier/InProgress${url}`)
        this.orders = data.results
        this.totalItems = data.count
        this.calculateLastPickUpCodeSentDates()
        this.calculateSentPickUpCodeCounts()
      } catch (error) {}
      this.loading = false
    },
    sortBy (options) {
      this.orderBy = options[0]
    },
    sortDesc (options) {
      this.orderDesc = options[0]
      console.log(options[0])
    },
    handlePageChange (value) {
      this.page = value
      this.getOrders()
    },
    pagination (options) {
      this.page = options.page
      this.itemsPerPage = options.itemsPerPage
    },
    details (item) {
      this.$router.push({ name: 'CashierOrder', params: { id: item.id } })
    },
    calculateLastPickUpCodeSentDates () {
      this.orders.forEach((order) => {
        const allLogs = order.logs
        const actionsToCheck = [
          "Resend order's pickup code",
          'Mark as ready for pick up'
        ]
        for (const actionName of actionsToCheck) {
          const logsForAction = allLogs
            .filter(log => log.actionName === actionName)
            .map(log => new Date(log.date))
          if (logsForAction.length > 0) {
            order.lastPickUpCodeSentDate = logsForAction.pop().toISOString()
            return
          }
        }
        order.lastPickUpCodeSentDate = null
      })
    },
    calculateSentPickUpCodeCounts () {
      this.orders.forEach((order) => {
        const resendLogs = order.logs.filter(log => log.actionName === "Resend order's pickup code")
        order.sentPickUpCodeCount = resendLogs.length + 1
      })
    }
  }
}
</script>
