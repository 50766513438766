<template>
  <div>
    <AppBar :title="$t('orders.title')" />
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-tabs v-model="tab" color="primary" class="px-4" @change="onTabChanged">
        <v-tab v-for="(item, index) in filteredTabs" :key="index">
          {{ item.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, index) in filteredTabs" :key="index">
          <v-card flat>
            <component :is="item.component" :ref="item.component"/>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Orders from './Orders.vue'
import Completed from './Completed.vue'
import ClickCollect from '../ClickCollect/Orders.vue'
import { cashierRoleId } from '@/utils/helpers'

export default {
  name: 'OrdersWraper',
  components: {
    Orders,
    ClickCollect,
    Completed
  },
  data () {
    return {
      tab: null
    }
  },
  methods: {
    onTabChanged () {
      if (this.$refs) {
        if (this.$refs.Orders && this.tab === 0) {
          this.$refs.Orders[0].resetParams()
        }
        if (this.$refs.Completed && this.tab === 2) {
          this.$refs.Completed[0].resetParams()
        }
      }
    }
  },
  computed: {
    tabs () {
      return [
        {
          title: this.$t('orders.currentOrders'),
          show: this.$store.getters.userRoleId !== cashierRoleId,
          component: 'Orders'
        },
        {
          title: this.$t('clickAndCollectOrders.title'),
          show: this.$store.getters.userRoleId !== cashierRoleId,
          component: 'ClickCollect'
        },
        {
          title: this.$t('completedOrders.title'),
          show: this.$store.getters.userRoleId !== cashierRoleId,
          component: 'Completed'
        }
      ]
    },
    filteredTabs () {
      return this.tabs.filter((tab) => tab.show)
    }
  }
}
</script>
