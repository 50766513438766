<template>
  <div>
    <AppBar :title="$t('orders.title')" />
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-tabs v-model="tab" color="primary" class="px-4" @change="onTabChanged">
        <v-tab v-for="(item, index) in filteredTabs" :key="index">
          {{ item.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, index) in filteredTabs" :key="index">
          <v-card flat>
            <component :is="item.component" :ref="item.component" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import CashierOrders from './CashierOrders.vue'
import CashierCompleted from './CashierCompleted.vue'
import { cashierRoleId } from '@/utils/helpers'

export default {
  name: 'CashiersWrap',
  components: {
    CashierOrders,
    CashierCompleted
  },
  data () {
    return {
      tab: null
    }
  },
  methods: {
    onTabChanged () {
      if (this.$refs) {
        if (this.$refs.Orders && this.tab === 0) {
          this.$refs.CashierOrders[0].resetParams()
        }
        if (this.$refs.Completed && this.tab === 2) {
          this.$refs.CashierCompleted[0].resetParams()
        }
      }
    }
  },
  computed: {
    tabs () {
      return [
        {
          title: this.$t('orders.currentOrders'),
          show: this.$store.getters.userRoleId === cashierRoleId,
          component: 'CashierOrders'
        },
        {
          title: this.$t('completedOrders.title'),
          show: this.$store.getters.userRoleId === cashierRoleId,
          component: 'CashierCompleted'
        }
      ]
    },
    filteredTabs () {
      return this.tabs.filter((tab) => tab.show)
    }
  }
}
</script>
