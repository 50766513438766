<template>
  <div>
    <div ref="dataTableCompleted"></div>
    <v-card flat class="rounded-xl pb-4">
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('completedOrders.searchLabel')"
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="2">
            <v-select
            v-model="type"
            :items="types"
            :label="$t('completedOrders.typeLabel')"
            clearable
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-select
            v-model="paymentMethod"
            :items="methods"
            :label="$t('completedOrders.paymentMethodLabel')"
            clearable
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-select
            v-model="shoppingCenterId"
            :items="shops"
            item-text="name"
            item-value="id"
            :label="$t('completedOrders.shoppingCenterLabel')"
            clearable
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-menu
              v-model="menuDateFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom"
                  :label="$t('completedOrders.dateFromLabel')"
                  persistent-hint
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                no-title
                @input="menuDateFrom = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-menu
              v-model="menuDateTo"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateTo"
                  :label="$t('completedOrders.dateToLabel')"
                  persistent-hint
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                no-title
                @input="menuDateTo = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        @update:page="$vuetify.goTo($refs.dataTableCompleted)"
        @update:sort-by="sortBy"
        @update:sort-desc="sortDesc"
        :headers="headers"
        :items="orders"
        :items-per-page="itemsPerPage"
        :loading="loading"
        :page="page"
        @click:row="details"
        @pagination="pagination"
        :server-items-length="totalItems"
        hide-default-footer
        :multi-sort="false"
        :must-sort="true"
        >
        <template v-slot:item.created="{ item }">
          {{ item.created | dateFormat('YYYY-MM-DD') }}
        </template>
        <template v-slot:item.shoppingCenters="{ item }">
          <div v-if="item.shoppingCenters.length > 1">{{ $t('completedOrders.multipleShoppingCenters') }}</div>
          <div v-else>{{ item.shoppingCenters[0].name }}</div>
        </template>
        <template v-slot:footer v-if="pageCount > 1">
          <v-pagination v-model="page" @input="handlePageChange" class="mt-4" :length="pageCount" :total-visible="7" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
import _ from 'lodash'
export default {
  name: 'Completed',
  data () {
    return {
      loading: false,
      types: [
        { text: this.$t('orderTypes.shipping'), value: 1 },
        { text: this.$t('orderTypes.pickup'), value: 2 },
        { text: this.$t('orderTypes.clickAndCollect'), value: 3 }
      ],
      methods: [
        { text: this.$t('paymentMethods.onlinePayment'), value: 1 },
        { text: this.$t('paymentMethods.traditionalTransfer'), value: 2 }
      ],
      shops: [],
      orders: [],
      search: '',
      dateFrom: null,
      dateTo: null,
      type: null,
      shoppingCenterId: null,
      menuDateFrom: false,
      menuDateTo: false,
      paymentMethod: null,
      page: 1,
      itemsPerPage: 50,
      totalItems: null,
      headers: [
        { text: this.$t('orderHeaders.date'), align: 'start', value: 'created' },
        { text: this.$t('orderHeaders.orderNumber'), sortable: false, value: 'businessId' },
        { text: this.$t('orderHeaders.buyer'), sortable: false, value: 'receiverName' },
        { text: this.$t('orderHeaders.email'), sortable: false, value: 'email' },
        { text: this.$t('orderHeaders.status'), sortable: false, value: 'state' },
        { text: this.$t('orderHeaders.paymentMethod'), sortable: false, value: 'paymentMethod' },
        { text: this.$t('orderHeaders.type'), sortable: false, value: 'type' },
        { text: this.$t('orderHeaders.shoppingCenterName'), sortable: false, value: 'shoppingCenters' },
        { text: this.$t('orderHeaders.card'), value: 'giftCardsChargeAmount' },
        { text: this.$t('orderHeaders.shipping'), value: 'shippingPrice' },
        { text: this.$t('orderHeaders.totalAmount'), value: 'totalChargeAmount' }
      ]
    }
  },
  async created () {
    if (this.$route.query.page) this.page = +this.$route.query.page
    if (this.$route.query.type) this.type = +this.$route.query.type
    if (this.$route.query.paymentMethod) this.paymentMethod = +this.$route.query.paymentMethod
    if (this.$route.query.shoppingCenterId) this.shoppingCenterId = +this.$route.query.shoppingCenterId
    if (this.$route.query.dateFrom) this.dateFrom = this.$route.query.dateFrom
    if (this.$route.query.dateTo) this.dateTo = this.$route.query.dateTo

    await this.getOrders()
    await this.getCenters()
    this.debouncedGetOrders = _.debounce(this.getOrders, 500)
  },
  computed: {
    pageCount () {
      try {
        return Math.ceil(this.totalItems / this.itemsPerPage)
      } catch {
        return 1
      }
    }
  },
  watch: {
    search () {
      this.page = 1
      this.debouncedGetOrders()
    },
    type () {
      this.page = 1
      this.getOrders()
    },
    paymentMethod () {
      this.page = 1
      this.getOrders()
    },
    shoppingCenterId () {
      this.page = 1
      this.getOrders()
    },
    dateFrom () {
      this.page = 1
      this.getOrders()
    },
    dateTo () {
      this.page = 1
      this.getOrders()
    }
  },
  methods: {
    async resetParams () {
      this.search = ''
      this.dateFrom = null
      this.dateTo = null
      this.type = null
      this.shoppingCenterId = null
      this.paymentMethod = null
      this.page = 1
      await this.getOrders()
    },
    async getOrders () {
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')
      let url = `?page=${this.page}&size=${this.itemsPerPage}&countryCode=${countryCode}`

      if (this.state) {
        url += `&state=${this.state}`
      }
      if (this.type) {
        url += `&type=${this.type}`
      }
      if (this.paymentMethod) {
        url += `&paymentMethod=${this.paymentMethod}`
      }
      if (this.shoppingCenterId) {
        url += `&shoppingCenterId=${this.shoppingCenterId}`
      }
      if (this.dateFrom) {
        url += `&dateFrom=${this.dateFrom}`
      }
      if (this.dateTo) {
        url += `&dateTo=${this.dateTo}`
      }
      if (this.orderBy) {
        url += `&orderBy=${this.orderBy}`
        if (this.orderDesc) {
          url += `&isAscending=${this.orderDesc}`
        }
      }
      if (this.search) {
        url += `&search=${this.search}`
      }

      window.history.pushState(null, '', url)

      try {
        const { data } = await Service.get(`Orders/Completed${url}`)
        this.orders = data.results
        this.totalItems = data.count
      } catch (error) {}
      this.loading = false
    },
    sortBy (options) {
      this.orderBy = options[0]
    },
    sortDesc (options) {
      this.orderDesc = options[0]
      console.log(options[0])
    },
    async getCenters () {
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')
      try {
        const { data } = await Service.get(`/ShoppingCenters/Management?countryCode=${countryCode}`)
        this.shops = data
      } catch (error) {}
      this.loading = false
    },
    handlePageChange (value) {
      this.page = value
      this.getOrders()
    },
    pagination (options) {
      this.page = options.page
      this.itemsPerPage = options.itemsPerPage
    },
    details (item) {
      this.$router.push({ name: 'Order', params: { id: item.id } })
    }
  }
}
</script>
