<template>
  <div>
    <v-card flat class="rounded-xl pt-2 pb-4">
      <v-data-table
      :headers="headers"
      :items="orders"
      :items-per-page="25"
      :loading="loading">
        <template v-slot:item.edit="{ item }">
          <v-btn depressed @click="$router.push({ name: 'ClickCollectOrder', params: { id: item.id } })" rounded>{{ $t('clickAndCollectOrders.preview') }}</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import Service from '@/services'
export default {
  name: 'orders',
  data () {
    return {
      orders: [],
      loading: false,
      headers: [
        {
          text: '',
          width: '100',
          value: 'id'
        },
        {
          text: this.$t('clickAndCollectOrders.client'),
          value: 'fullName'
        },
        {
          text: this.$t('clickAndCollectOrders.email'),
          value: 'email'
        },
        {
          text: this.$t('clickAndCollectOrders.shoppingCenter'),
          value: 'shoppingCenterName'
        },
        {
          text: this.$t('clickAndCollectOrders.amount'),
          value: 'chargeAmount'
        },
        {
          sortable: false,
          align: 'end',
          width: '100',
          value: 'edit'
        }
      ]
    }
  },
  created () {
    this.getOrders()
  },
  computed: {},
  methods: {
    async getOrders () {
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')
      try {
        const { data } = await Service.get(`/ClickCollect?countryCode=${countryCode}`)
        this.orders = data
      } catch (error) {}
      this.loading = false
    }
  }
}
</script>
